<template>
  <FormPromotion :loading="loading" />
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'

import store from '@/store'

import promotionAgStoreModule from '@promotionAg/promotionAgStoreModule'

export default {
  components: {
    FormPromotion: () => import('../components/FormPromotion.vue'),
  },

  setup() {
    const loading = ref(false)
    const PROMOTION_AG_APP_STORE_MODULE_NAME = 'app-promotionAg'

    // Register module
    if (!store.hasModule(PROMOTION_AG_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROMOTION_AG_APP_STORE_MODULE_NAME, promotionAgStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROMOTION_AG_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROMOTION_AG_APP_STORE_MODULE_NAME)
      }
    })

    const promotionDataToAdd = ref({})

    return {
      loading,
      promotionDataToAdd,
    }
  },
}
</script>
